<template>
  <section>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->
    <!-- <va-breadcrumbs>
        <va-breadcrumbs-item label="Educacao" to="/admin/educacao/segmentos" />
        <va-breadcrumbs-item>
          <span style="font-style: italic; color: #0785d3"
            >Aluno historico</span
          >
        </va-breadcrumbs-item>
    </va-breadcrumbs> -->
    <div class="p-fluid formgrid grid">
      <div class="field col-12 md:col-10">
        <h2 class="titulo-links-escolha">Aluno Histórico</h2>
      </div>
    </div>

    <div class="pm-card">
      <h3 style="font-size: 17px;">Histórico do Aluno</h3>
      <div class="field col-12 md:col-10">
        <div class="field col-12 md:col-8 lg:col-3">
          <label>Selecione o Ano</label>
          <select class="p-inputtext p-component" v-model="ano"
            style="appearance: revert !important;width: 100% !important;" >
            <option v-for="n in anoALL" :key="n" :value="n" >{{n}}</option>
          </select>
        </div>

        <pm-Button label="Gerar Histórico PDF"  class="p-button-primary mt-5" @click="generateHistoryPdf()" />
      </div>
    </div>

    <div class="pm-card">
      <h3 style="font-size: 17px;">Histórico de Eventos</h3>
      <pm-DataTable :value="item" dataKey="id" :rowHover="true" >
        <pm-Column field="tipoEvento" header=""></pm-Column>

        <pm-Column field="ano" header="ANO"></pm-Column>

        <pm-Column field="escola" header="ESCOLA"></pm-Column>

        <pm-Column field="segmento" header="SEGMENTO"></pm-Column>

        <pm-Column field="serie" header="PERÍODO"></pm-Column>

        <pm-Column field="turma_nome" header="TURMA"></pm-Column>

        <pm-Column field="entrou_turma_data" header="DATA ENTRADA TURMA"></pm-Column>

      </pm-DataTable>

      <div class="field col-12 md:col-2">
        <pm-Button label="Voltar"  class="p-button-danger mt-5" icon="pi pi-angle-left" iconPos="left" @click="voltar()" />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Aluno } from "@/class/alunos.js";
import { SegmentoEscolar } from "@/class/segmentoEscolar.js";
import { SeriesEscolar } from "@/class/serie.js";
import { Escola } from "@/class/escolas.js"
import DataTables from "@/pages/admin/tables/data-tables/DataTables.vue";
import { Calendario } from "@/class/calendario";

export default defineComponent({
    props: {
        id: {},
        pg: {}
    },
    data() {
      return {
        home: { icon: "pi pi-home", to: "#" },
        items_bread: [
          { label: "Aluno Histórico" },
        ],
        item: [],
        ano:0,
        hist:[],
        anoALL: [2023]
      };
    },
    methods: {
      voltar() {
        this.$router.push({ name: "coordenacao-listaalunos", params: { pg: this.pg } });
      },
      async historicoAluno() {
        try {
          const aluno = {
            "aluno_id": this.id
          };
          const data = await Aluno.historicoAluno(aluno);
          //percorre array para pegar todo o histórico do aluno
          for (let i = 0; i < data.data.length; i++) {
            const el = data.data[i];
            //let seg = await SegmentoEscolar.obtemUm(el.segmento_id);
            //let seri = await SeriesEscolar.obtemUm(el.serie_id);
            const esc = await Escola.obtemUm(el.escola_id);

            /*if(el.evento != null){
              let n = {
                aluno_id: el.aluno_id,
                ano: "",
                escola: "",
                segmento:"",
                serie: "",
                turma_nome: "",
                entrou_turma_data: "",
                tipoEvento: el.evento.tipoEvento,
              }
              this.item.push(n);
            }*/

            if(el.evento != null){
              if(el.evento.tipoEvento == "Transferencia de Turma"){
                const novo = {
                  aluno_id: el.aluno_id,
                  ano: el.ano,
                  escola: esc.data.nome,
                  segmento:el.segmento_nome,
                  serie: el.serie_nome,
                  turma_nome: el.turma_nome,
                  entrou_turma_data: el.entrou_turma_data,
                  tipoEvento: el.evento.tipoEvento,
                };
                this.item.push(novo);
              }else{
                const novo = {
                  aluno_id: el.aluno_id,
                  ano: "",
                  escola: "",
                  segmento: "",
                  serie: "",
                  turma_nome: "",
                  entrou_turma_data: el.entrou_turma_data,
                  tipoEvento: el.evento.tipoEvento,
                };
                this.item.push(novo);
              }
            }else{
              const novo = {
                aluno_id: el.aluno_id,
                ano: el.ano,
                escola: esc.data.nome,
                //segmento: seg.data.nome,
                segmento:el.segmento_nome,
                serie: el.serie_nome,
                //serie: seri.data.nome,
                turma_nome: el.turma_nome,
                entrou_turma_data: el.entrou_turma_data,
                tipoEvento: "ENTROU NA TURMA",
              };
              this.item.push(novo);
            }

          }
        }catch (e) {
          // console.log(e);
        }
      },

      async history(){
        const data = await Aluno.history(this.id, this.ano);
        this.hist = data.data.data;

        const n = {
          disciplina_id: 1,
         nome: "TODAS AS DICIPLINAS",
        };

        this.hist["disciplina"].push(n)

        console.log(this.hist)
      },

      /*montarMesPdf(presenca, disciplina , mes="Fevereiro"){
        var mywindow = window;
        if(presenca.length !== 0){
          mywindow.document.write(`<h4 style="text-align: left;">${mes}</h4>`);
          for (let index = 0; index < disciplina.length; index++) {
            if( presenca[disciplina[index]["disciplina_id"]] ){

              if(disciplina[index]["nome"] != "TODAS AS DICIPLINAS" && disciplina[index]["nome"] != "EDUCAÇÃO INFANTIL"){
                 mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ disciplina[index]["nome"]  } </h5>`);
              }

              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of presenca[disciplina[index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                  mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                  if(it.presenca == 1){
                    mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                  }else{
                    if(it.justificada_falta == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                    }else{
                      mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                    }
                  }

                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }
        return mywindow;
      },*/

      async anosCalendario () {
        try {
          let ano;
          const dt = await Calendario.ano();
          ano = dt.data;
          ano.sort((a, b) => (a.ano > b.ano) ? 1 : ((b.ano > a.ano) ? -1 : 0));
          this.anoALL = [];
          for (const l of ano) {
            this.anoALL.push(l.ano)
          }
        } catch (e) {

        }
      },

      async generateHistoryPdf(){
        const mywindow = window.open('', 'PRINT', 'height=auto,width=800');
        mywindow.document.write(`<html><head> <title> histórico ${this.ano} </title>`);
        mywindow.document.write(`
          <style>
            body {
                margin: 0;
                color: #212529;
                text-align: center;
                background-color: #fff;
            }
            table {
                border-collapse: collapse;
            }
            table tr td, table tr th{
              font-size: 12px !important;
            }
            thead {
              display: table-header-group;
              vertical-align: middle;
              border-color: inherit;
            }
            tr {
              display: table-row;
              vertical-align: inherit;
              border-color: inherit;
            }
            .table-bordered {
                border: 1px solid #dee2e6;
            }
            .table {
                width: 100%;
                margin-bottom: 1rem;
                color: #212529;
            }
            .table-bordered thead td, .table-bordered thead th {
                border-bottom-width: 2px;
            }
            .table thead th {
              vertical-align: bottom;
              border-bottom: 2px solid #dee2e6;
            }
            .table-bordered td, .table-bordered th {
                border: 1px solid #dee2e6;
            }
            .table td, .table th {
              padding: 0.75rem;
            }
            b,p,h3,h4{
              text-transform: uppercase;
            }
            .verde{
              background-color:#50d32f;
            }
            .amarelo{
              background-color: #c9d32f;
            }
            .vermelho{
              background-color: #D32F2F;
            }
          </style>
        `);
        mywindow.document.write('</head><body >');

        mywindow.document.write(`
        <div style="text-align: left; margin-top:20px;">
          <b> Matrículado </b>`);
        mywindow.document.write(`
          <p style="text-align: center;" > ${ this.hist["turma"]["escola"] } </p>
          <p style="text-align: center;"> ${ this.hist["turma"]["segmento"]}</p>
          <p style="text-align: center;"> ${ this.hist["turma"]["serie"] } - ${ this.hist["turma"]["nome"] } - ${ this.hist["turma"]["turno"] }</p>

        </div>`);



        //notas
        if(this.hist["notas"].length !== 0){

          mywindow.document.write('<h3 style="text-align: center;margin-top:40px;">Notas</h3>');

          for (const it of this.hist["notas"]) {
              mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ it.disciplina }</h5>`);
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> 1° Periodo </th>
                      <th> 2° Periodo </th>
                      <th> 3° Periodo </th>
                      <th> 4° Periodo </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

                mywindow.document.write(`
                    <tr>`
                  );

                  if(it.periodo_1 !="0"){mywindow.document.write(`<td style="text-align:center;">${ it.periodo_1 }</td>`); }else{mywindow.document.write(`<td style="text-align:center;"> -- </td>`); }
                  if(it.periodo_2 !="0"){mywindow.document.write(`<td style="text-align:center;">${ it.periodo_2 }</td>`); }else{mywindow.document.write(`<td style="text-align:center;"> -- </td>`); }
                  if(it.periodo_3 !="0"){mywindow.document.write(`<td style="text-align:center;">${ it.periodo_3 }</td>`); }else{mywindow.document.write(`<td style="text-align:center;"> -- </td>`); }
                  if(it.periodo_4 !="0"){mywindow.document.write(`<td style="text-align:center;">${ it.periodo_4 }</td>`); }else{mywindow.document.write(`<td style="text-align:center;"> -- </td>`); }

                mywindow.document.write(`
                    </tr>`
                  );

              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');

          }
        }


        //presenças
        mywindow.document.write('<h3 style="text-align: center;margin-top:40px;">Presenças</h3>');

        if(this.hist["presencas"][1].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Janeiro</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][1][this.hist["disciplina"][index]["disciplina_id"]] ){
                if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                   mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

                }
                mywindow.document.write(`<table class="table table-bordered">`);
                mywindow.document.write(`
                    <thead>
                      <tr>
                        <th> Data </th>
                        <th> Presença </th>
                      </tr>
                    </thead>`
                );

                mywindow.document.write(`
                  <tbody>`
                );

                for (const it of this.hist["presencas"][1][this.hist["disciplina"][index]["disciplina_id"]]) {
                    mywindow.document.write(`
                      <tr>`
                    );

                      mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                      if(it.presenca == 1){
                        mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                      }else{
                        if(it.justificada_falta == 1){
                          mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                        }else{
                          mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                        }
                      }

                    mywindow.document.write(`
                      </tr>`
                    );
                }


                mywindow.document.write(`
                  </tbody>`
                );

                mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][2].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Fevereiro</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][2][this.hist["disciplina"][index]["disciplina_id"]] ){
              if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                 mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

              }
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of this.hist["presencas"][2][this.hist["disciplina"][index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                  mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                  if(it.presenca == 1){
                    mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                  }else{
                    if(it.justificada_falta == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                    }else{
                      mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                    }
                  }

                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][3].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Março</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][3][this.hist["disciplina"][index]["disciplina_id"]] ){
              if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

              }
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of this.hist["presencas"][3][this.hist["disciplina"][index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                  mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                  if(it.presenca == 1){
                    mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                  }else{
                    if(it.justificada_falta == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                    }else{
                      mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                    }
                  }

                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][4].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Abril</h4>');

          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][4][this.hist["disciplina"][index]["disciplina_id"]] ){
              if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                 mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

              }
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of this.hist["presencas"][4][this.hist["disciplina"][index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                    mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                    if(it.presenca == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                    }else{
                      if(it.justificada_falta == 1){
                        mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                      }else{
                        mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                      }
                    }
                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][5].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Maio</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][5][this.hist["disciplina"][index]["disciplina_id"]] ){
                if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                  mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

                }
                mywindow.document.write(`<table class="table table-bordered">`);
                mywindow.document.write(`
                    <thead>
                      <tr>
                        <th> Data </th>
                        <th> Presença </th>
                      </tr>
                    </thead>`
                );

                mywindow.document.write(`
                  <tbody>`
                );

                for (const it of this.hist["presencas"][5][this.hist["disciplina"][index]["disciplina_id"]]) {
                    mywindow.document.write(`
                      <tr>`
                    );

                    mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                    if(it.presenca == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                    }else{
                      if(it.justificada_falta == 1){
                        mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                      }else{
                        mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                      }
                    }

                    mywindow.document.write(`
                      </tr>`
                    );
                }


                mywindow.document.write(`
                  </tbody>`
                );

                mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][6].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Junho</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][6][this.hist["disciplina"][index]["disciplina_id"]] ){
              if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

              }
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of this.hist["presencas"][6][this.hist["disciplina"][index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                  mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                  if(it.presenca == 1){
                    mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                  }else{
                    if(it.justificada_falta == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                    }else{
                      mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                    }
                  }

                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][7].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Julho</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][7][this.hist["disciplina"][index]["disciplina_id"]] ){
              if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

              }
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of this.hist["presencas"][7][this.hist["disciplina"][index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                  mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                  if(it.presenca == 1){
                    mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                  }else{
                    if(it.justificada_falta == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                    }else{
                      mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                    }
                  }

                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][8].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Agosto</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][8][this.hist["disciplina"][index]["disciplina_id"]] ){
              if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

              }
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of this.hist["presencas"][8][this.hist["disciplina"][index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                  mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                  if(it.presenca == 1){
                    mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                  }else{
                    if(it.justificada_falta == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                    }else{
                      mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                    }
                  }

                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][9].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Setembro</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][9][this.hist["disciplina"][index]["disciplina_id"]] ){
              if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

              }
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of this.hist["presencas"][9][this.hist["disciplina"][index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                  mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                  if(it.presenca == 1){
                    mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                  }else{
                    if(it.justificada_falta == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                    }else{
                      mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                    }
                  }

                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][10].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Outubro</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][10][this.hist["disciplina"][index]["disciplina_id"]] ){
              if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

              }
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of this.hist["presencas"][10][this.hist["disciplina"][index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                  mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                  if(it.presenca == 1){
                    mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                  }else{
                    if(it.justificada_falta == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                    }else{
                      mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                    }
                  }

                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][11].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Novembro</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][11][this.hist["disciplina"][index]["disciplina_id"]] ){
              if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

              }
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of this.hist["presencas"][11][this.hist["disciplina"][index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                  mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                  if(it.presenca == 1){
                    mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                  }else{
                    if(it.justificada_falta == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                    }else{
                      mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                    }
                  }

                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }


        if(this.hist["presencas"][12].length !== 0){
          mywindow.document.write('<h4 style="text-align: left;">Dezembro</h4>');
          for (let index = 0; index < this.hist["disciplina"].length; index++) {
            if( this.hist["presencas"][12][this.hist["disciplina"][index]["disciplina_id"]] ){
              if(this.hist["disciplina"][index]["nome"] != "TODAS AS DICIPLINAS" && this.hist["disciplina"][index]["nome"] != "EDUCAÇÃO INFANTIL"){

                mywindow.document.write(`<h5 style="text-align: left;margin-top:30px;">${ this.hist["disciplina"][index]["nome"]  } </h5>`);

              }
              mywindow.document.write(`<table class="table table-bordered">`);
              mywindow.document.write(`
                  <thead>
                    <tr>
                      <th> Data </th>
                      <th> Presença </th>
                    </tr>
                  </thead>`
              );

              mywindow.document.write(`
                <tbody>`
              );

              for (const it of this.hist["presencas"][12][this.hist["disciplina"][index]["disciplina_id"]]) {
                  mywindow.document.write(`
                    <tr>`
                  );

                  mywindow.document.write(`<td style="text-align:center;">${ it.data_formt }</td>`);
                  if(it.presenca == 1){
                    mywindow.document.write(`<td style="text-align:center;" class="verde"> Presente </td>`);
                  }else{
                    if(it.justificada_falta == 1){
                      mywindow.document.write(`<td style="text-align:center;" class="amarelo"> Falta Justificada </td>`);
                    }else{
                      mywindow.document.write(`<td style="text-align:center;" class="vermelho"> Falta </td>`);
                    }
                  }

                  mywindow.document.write(`
                    </tr>`
                  );
              }


              mywindow.document.write(`
                </tbody>`
              );

              mywindow.document.write('</table>');
            }
          }
        }



        mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus();

        mywindow.print();
        mywindow.close();
      }

    },
    beforeMount() {
        if (this.id == null) {
            this.$router.push({ name: "coordenacao-listaalunos", params: { pg: this.pg } });
        }
        else {
          this.ano = parseInt(sessionStorage.getItem("anoSelecionado"));
          this.anosCalendario();
          this.historicoAluno();
          this.history();
        }
    },
    components: { DataTables }
});
</script>

<style lang="scss" scoped>
.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>
